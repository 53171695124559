// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-recipe-js": () => import("./../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-random-recipe-by-tag-js": () => import("./../src/templates/randomRecipeByTag.js" /* webpackChunkName: "component---src-templates-random-recipe-by-tag-js" */),
  "component---src-templates-list-by-tag-js": () => import("./../src/templates/listByTag.js" /* webpackChunkName: "component---src-templates-list-by-tag-js" */),
  "component---src-templates-list-js": () => import("./../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

